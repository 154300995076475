import React from "react";

interface ButtonWrapperComponentProps {
  onSubmit: () => void;
  nextButtonText: string;
  resetButtonText: string;
}

export default function ButtonWrapperComponent({
  onSubmit,
  nextButtonText,
  resetButtonText,
}: ButtonWrapperComponentProps) {
  return (
    <div className="button-wrapper">
      <button type="reset" className="btn btn-secondary">
        {resetButtonText}
      </button>
      <button type="submit" className="btn btn-primary mr-2" onClick={onSubmit}>
        {nextButtonText}
      </button>
    </div>
  );
}
