import react, { useEffect } from "react";
import { useVisibility, Utils } from "../shared";

export const VOHomeComponent: React.FC<any> = ({
  ...props
}: any): JSX.Element => {
  const { setIsVisible } = useVisibility();

  useEffect(() => {
    {
      /* 
  //publisher snippet to insert on HS Page
   <script>
   const lnkInsOverview = document.querySelector('a[href="/mypages/insurance-overview?hsLang=sv"]');
   const lnkMyProfile = document.querySelector('a[href="/mypages/my-profile?hsLang=sv"]');
    lnkInsOverview.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/insurance-overview'
            }, 'http://localhost:3000');
        });
   lnkMyProfile.addEventListener('click', function(event) {
                event.preventDefault();
        window.parent.postMessage({
                action: 'navigate',
                url: '/vo-profile'
            }, 'http://localhost:3000');
        });
    </script> */
    }

    //setIsVisible(false);
    window.addEventListener("message", (event) => {
      if (event.data.action === "navigate") {
        console.log(event.data.url);
        window.location.href = event.data.url;
      }
    });
    return () => setIsVisible(true);
  }, [setIsVisible]);

  return (
    <>
      <iframe
        scrolling="no"
        style={{ width: "100%", height: "1334px" }}
        src={Utils.voHomeUrl}
      ></iframe>
    </>
  );
};
