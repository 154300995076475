import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

export default function ProductComponent({
  productDetails,
  onProductIsSelected,
}) {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);

  useEffect(() => {
    if (productDetails) {
      productDetails.data.sort((a, b) => a.pId - b.pId);
      setProducts(productDetails.data);
    }
  }, [productDetails]);

  const handleFieldChange = (productId, fieldName, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.pId === productId) {
          return { ...product, [fieldName]: value };
        }
        return product;
      })
    );

    if (fieldName == "startDate") {
      const selectedProduct = products.find(
        (product) => product.pId === productId
      );
      const date = new Date(value);
      const endDate = new Date(
        date.setMonth(date.getMonth() + selectedProduct.period)
      );

      const name = selectedProduct.productName;

      onProductIsSelected(
        true,
        productId,
        name,
        selectedProduct.period,
        date,
        endDate
      );

      setSelectedProductId(id);
    }

    if (fieldName == "period") {
      const selectedProduct = products.find(
        (product) => product.pId === productId
      );
      const date = new Date(selectedProduct.startDate);
      const endDate = new Date(date.setMonth(date.getMonth() + value));

      const name = selectedProduct.productName;

      onProductIsSelected(
        true,
        productId,
        name,
        value,
        selectedProduct.startDate,
        endDate
      );

      setSelectedProductId(id);
    }
  };

  const chooseProduct = (id) => {
    const selectedProduct = products.find((product) => product.pId === id);
    const date = new Date(selectedProduct.startDate);
    const endDate = new Date(
      date.setMonth(date.getMonth() + selectedProduct.period)
    );

    const name = selectedProduct.productName;

    onProductIsSelected(
      true,
      id,
      name,
      selectedProduct.period,
      selectedProduct.startDate,
      endDate
    );
    setSelectedProductId(id);
  };

  const removeProduct = () => {
    onProductIsSelected(false, 0, 0);
    setSelectedProductId(0);
  };

  return (
    <table className="table mt-20">
      <thead>
        <tr>
          <th>
            {t("warranty_registration.product_deatils.select_product_header")}
          </th>
          <th>
            {t("warranty_registration.product_deatils.deductible_header")}
          </th>
          <th>
            {t("warranty_registration.product_deatils.start_date_header")}
          </th>
          <th>
            {t("warranty_registration.product_deatils.period_date_header")}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {products.map((product) => (
          <tr key={product.pId}>
            <td
              width="50%"
              className="align-vertical-middle font-size-16"
              style={{
                backgroundColor:
                  selectedProductId === 0 || selectedProductId === product.pId
                    ? "white"
                    : "#E0E0E0",
              }}
            >
              {product.productName}
            </td>
            <td
              width="10%"
              className="align-vertical-middle font-size-12"
              style={{
                backgroundColor:
                  selectedProductId === 0 || selectedProductId === product.pId
                    ? "white"
                    : "#E0E0E0",
              }}
            >
              {product.excessAmount}
            </td>
            <td
              width="12%"
              style={{
                backgroundColor:
                  selectedProductId === 0 || selectedProductId === product.pId
                    ? "white"
                    : "#E0E0E0",
              }}
            >
              <DatePicker
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"],
                  },
                  preventOverflow: {
                    enabled: false,
                  },
                  hide: {
                    enabled: false,
                  },
                }}
                className="input-style-2"
                dateFormat="yyyy-MM-dd"
                minDate={new Date().setHours(0, 0, 0, 0)}
                selected={new Date(product.startDate)} // Use the product's specific startDate
                readOnly={
                  selectedProductId === 0 || selectedProductId === product.pId
                    ? false
                    : true
                }
                onChange={(date) => {
                  handleFieldChange(product.pId, "startDate", date);
                }}
              />
            </td>
            <td
              width="12%"
              style={{
                backgroundColor:
                  selectedProductId === 0 || selectedProductId === product.pId
                    ? "white"
                    : "#E0E0E0",
              }}
            >
              <select
                className="period-dropdown"
                value={product.period} // Use the product's specific period
                onChange={(e) =>
                  handleFieldChange(
                    product.pId,
                    "period",
                    parseInt(e.target.value)
                  )
                }
                disabled={
                  selectedProductId === 0 || selectedProductId === product.pId
                    ? false
                    : true
                }
              >
                {product.periods.map((period, index) => (
                  <option key={index} value={period}>
                    {period}
                  </option>
                ))}
              </select>
            </td>
            <td
              width="10%"
              style={{
                backgroundColor:
                  selectedProductId === 0 || selectedProductId === product.pId
                    ? "white"
                    : "#E0E0E0",
              }}
            >
              {product.pId === selectedProductId ? (
                <button
                  className="btn btn-danger remove-btn"
                  onClick={() => {
                    removeProduct();
                  }}
                  disabled={
                    selectedProductId === 0 || selectedProductId === product.pId
                      ? false
                      : true
                  }
                >
                  {t("warranty_registration.product_deatils.remove_button")}
                </button>
              ) : (
                <button
                  className="btn btn-primary choose-btn"
                  onClick={() => {
                    chooseProduct(product.pId);
                  }}
                  disabled={
                    selectedProductId === 0 || selectedProductId === product.pId
                      ? false
                      : true
                  }
                >
                  {t("warranty_registration.product_deatils.choose_button")}
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
