import { initialState, IState } from "../../../redux/reducers";

export class Utils {
  public static readonly voLoginUrl: string = process.env.REACT_APP_VO_LOGIN_URL!;
  public static readonly dealerLoginUrl:string=process.env.REACT_APP_DEALER_LOGIN_URL!;
  public static readonly voHomeUrl: string = process.env.REACT_APP_VOHOME_URL!;
  public static readonly dHomeUrlSW:string=process.env.REACT_APP_DEALER_HSLANDING_SW!;
  public static readonly dHomeUrlNO:string=process.env.REACT_APP_DEALER_HSLANDING_NO!;
  public static readonly dHomeUrlFI:string=process.env.REACT_APP_DEALER_HSLANDING_FI!;
  public static readonly dHomeUrlDK:string=process.env.REACT_APP_DEALER_HSLANDING_DK!;
  public static readonly voLandUrl:string=process.env.REACT_APP_VO_LANDING_PAGE_URL!;
  public static readonly dearlerLandUrl:string=process.env.REACT_APP_DEALER_LANDING_PAGE_URL!;
  public static generateGuid: Function = (): string => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  public static getQueryStringValue: Function = (key:string): any => {
    return new URLSearchParams(window.location.search).get(key);
  };

  public static isNullOrEmpty:Function=(value: string | null | undefined): boolean =>{
    if (value === null || value === undefined || value.trim() === "" || value.trim() === "null") {
      return true;
    }
    return false;
  }

  public static loadPersistedState: Function = (): IState => {
    try {
      const serializedState = sessionStorage.getItem("state");
      if (serializedState === null) {
        return initialState;
      }
      return JSON.parse(serializedState);
    } catch (error) {
      return initialState;
    }
  };

  public static savePersistState: Function = (state: IState): void => {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  };

  public static currencyFormat: Function = (
    lCode: string,
    price: number
  ): string => {
    return new Intl.NumberFormat(lCode, {
      style: "currency",
      currency: "SEK",
      minimumFractionDigits: 2,
    }).format(price);
  };
}
